<template>
  <div class="MonitorItem">
    <div class="section section-name" :class="[monitor.disabled ? 'disabled' : '']">
      <base-checkbox v-model="monitor.isSelected" class="mb-0 section-checkbox"/>

      <monitor-paused-indicator v-if="!monitorCopy.is_running || monitorCopy.disabled"
                                ref="indicator" class="pause-indicator"/>
      <monitor-label :monitor="monitorCopy"
                     :class="{ 'ml-2': !monitorCopy.is_running }" />
    </div>

    <div class="section section-status" :class="[monitor.disabled ? 'disabled' : '']">
      <monitor-status class="w-100"
                      :monitor="monitorCopy" />
    </div>

    <div class="section section-certificate" :class="[monitor.disabled ? 'disabled' : '']">
      <monitor-ssl-certificate class="w-100"
                               :monitor="monitorCopy" />
    </div>

    <div class="section section-uptime" :class="[monitor.disabled ? 'disabled' : '']">
      <monitor-uptime class="w-100"
                      :uptime="monitorCopy.uptime" />
    </div>

    <div class="section section-latest-check" :class="[monitor.disabled ? 'disabled' : '']">
      <router-link ref="latestCheckLink"
                   :to="latestCheckRoute"
                   v-if="monitorCopy.latest_check">
        {{ latestCheckTime }}
      </router-link>
      <span v-else>Not checked yet</span>
    </div>

    <div class="section section-actions">
      <monitor-actions
        :monitor="monitorCopy"
        @run="onRun"
        @pause="onPause"
        @delete="$emit('delete', $event)"
        @enable="$emit('enable', $event)"
        @duplicated="$emit('duplicated')"
      />
    </div>
  </div>
</template>

<script>
import MonitorPausedIndicator from '@/components/Monitor/MonitorPausedIndicator.vue'
import MonitorLabel from '@/components/Monitor/MonitorLabel.vue'
import MonitorStatus from '@/components/Monitor/MonitorStatus.vue'
import MonitorSslCertificate from '@/components/Monitor/MonitorSslCertificate.vue'
import MonitorUptime from '@/components/Common/Uptime.vue'
import MonitorActions from './MonitorItemActions.vue'

import monitorsApi from '@/api/monitorsApi.js'
import tippy from 'tippy.js'
import dayjs from 'dayjs'

export default {
  components: {
    MonitorPausedIndicator,
    MonitorLabel,
    MonitorStatus,
    MonitorSslCertificate,
    MonitorUptime,
    MonitorActions
  },

  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      monitorCopy: { ...this.monitor }
    }
  },

  created () {
    this.listenForStatusUpdates()
  },

  mounted () {
    if (this.monitorCopy.latest_check) {
      tippy(this.$refs.latestCheckLink.$el, {
        content: this.$options.filters.longDate(this.monitorCopy.latest_check.created_at),
        interactive: true
      })
    }
  },

  methods: {
    listenForStatusUpdates () {
      window.Echo.private(`monitors.${this.monitorCopy.id}`)
        .listen('.online', data => {
          this.updateMonitor(data.id)
        })
        .listen('.offline', data => {
          this.updateMonitor(data.id)
        })
        .listen('.SSLCertificateDownloadFailed', data => {
          this.updateMonitor(data.id)
        })
        .listen('.SSLCertificateDownloaded', data => {
          this.updateMonitor(data.id)
        })
    },

    async updateMonitor (id) {
      this.monitorCopy = await monitorsApi.find(id)
    },

    onRun () {
      this.$set(this.monitorCopy, 'is_running', true)
      this.$set(this.monitorCopy, 'status', null)
      // this.$refs.indicator.triggerHint()
    },

    onPause () {
      this.$set(this.monitorCopy, 'is_running', false)
      this.$nextTick(() => {
        this.$refs.indicator.triggerHint()
      })
    }
  },

  computed: {
    latestCheckTime () {
      return dayjs.unix(this.monitorCopy.latest_check?.created_at).fromNow()
    },

    notificationsRoute () {
      return {
        name: 'monitors.single.notifications',
        params: {
          id: this.monitor.id
        }
      }
    },

    latestCheckRoute () {
      return {
        name: 'monitors.single.latestCheck',
        params: {
          id: this.monitor.id
        }
      }
    },

    ...mapState('system', [
      'warningUptimeThreshold',
      'dangerUptimeThreshold',
      'warningResponseTimeThreshold',
      'dangerResponseTimeThreshold'
    ])
  },

  watch: {
    monitor: {
      handler: function (newVal, oldVal) {
        this.monitorCopy = { ...this.monitor }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .MonitorItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 70px;
    //border: 1px solid rgb(212, 218, 231);
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(25,34,29,0.05);
    background-color: #fff;
    transition: box-shadow 200ms ease 0s, border-color 200ms ease 0s;

    .section-name {
      flex: 0 0 180px !important;
    }

    .disabled {
      pointer-events: none;
      cursor: not-allowed;
      opacity: 0.4;
      box-shadow: none;
    }

    .section-checkbox {
      margin-right: -20px;
      opacity: 0;
      pointer-events: visible !important;
    }

    & .section-checkbox.is-checked,
    &:hover .section-checkbox {
      opacity: 1;
    }

    &:hover .pause-indicator {
      opacity: 0;
      pointer-events: none;
    }

    & .section-checkbox.is-checked + .pause-indicator {
      opacity: 0;
      pointer-events: none;
    }

    ::v-deep .sortable:hover & .section-checkbox {
      opacity: 1;
    }

    @include monitor-item-columns;
  }
</style>
