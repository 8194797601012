<template>
  <div class="MonitorActions">
    <div ref="dots"
         class="dots-button"
         :class="{ open }">
      <icon-dots-horizontal width="16"
                            height="16"
                            color="rgb(178, 182, 195)" />
    </div>

    <div ref="dropdown">
      <nav class="nav" v-if="!monitor.disabled">
        <router-link class="nav-item"
                     :to="getRoute('overview')">Overview</router-link>

        <router-link class="nav-item"
                     :to="getRoute('nodes')">Locations</router-link>

        <router-link class="nav-item"
                     :to="getRoute('failedChecks')">Failed Checks</router-link>

        <router-link v-if="user.role !== role.Viewer && user.role !== role.Accountant" class="nav-item"
                     :to="getRoute('notifications')">Alerts</router-link>

        <a v-if="user.role !== role.Viewer && user.role !== role.Accountant" href="#"
           class="nav-item"
           @click.prevent="openTagsModal">Edit Tags</a>

        <router-link v-if="user.role !== role.Viewer && user.role !== role.Accountant" class="nav-item"
                     :to="getRoute('settings')">Advanced Settings</router-link>

        <div class="spacer"></div>
        <a
          v-if="user.role !== role.Viewer && user.role !== role.Accountant"
          href="#"
          class="nav-item"
          @click.prevent="duplicate"
        >
          Duplicate
        </a>
        <a
          v-if="user.role !== role.Viewer && user.role !== role.Accountant"
          href="#"
          class="nav-item"
          @click.prevent="resetMonitor"
        >
          Reset Stats
        </a>
        <template v-if="!monitor.is_running && user.role !== role.Viewer && user.role !== role.Accountant">
          <a href="#"
             class="nav-item"
             :class="{ '': !activeSubscription }"
             @click.prevent="run">Run</a>

          <p class="subscription-expired"
             v-if="!activeSubscription && user.role !== role.Viewer && user.role !== role.Accountant">
            You cannot run monitors because your
            <router-link :to="{ name: 'account.subscription' }">subscription</router-link>
            is not active
          </p>
        </template>

        <a
          v-if="monitor.is_running && user.role !== role.Viewer && user.role !== role.Accountant"
          href="#"
          class="nav-item"
          @click.prevent="pause"
        >
          Pause
        </a>

        <a
          v-if="user.role !== role.Viewer && user.role !== role.Accountant"
          href="#"
          class="nav-item"
          @click.prevent="onDelete"
        >
          Delete
        </a>
      </nav>
      <nav v-else class="nav">
        <a
          v-if="user.role !== role.Viewer && user.role !== role.Accountant && !user.exceeded_monitors_limit"
          href="#"
          class="nav-item"
          @click.prevent="onEnable"
        >
          Enable
        </a>
        <a
          v-if="user.role !== role.Viewer && user.role !== role.Accountant"
          href="#"
          class="nav-item"
          @click.prevent="onDelete"
        >
          Delete
        </a>
      </nav>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import monitorsApi from '@/api/monitorsApi.js'
import { Role } from '@/_helpers/role'
import swal from 'sweetalert2'

export default {
  props: {
    monitor: {
      required: true,
      type: Object
    }
  },

  data () {
    return {
      tippyInstance: null,
      open: false,
      role: Role,
      action: null
    }
  },

  mounted () {
    this.initDropdown()
  },

  methods: {
    initDropdown () {
      this.tippyInstance = tippy(this.$refs.dots, {
        content: this.$refs.dropdown,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'left-start',
        arrow: true,
        inertia: true,
        trigger: 'click',
        maxWidth: 'none',

        onShow: () => {
          this.open = true
        },

        onHide: () => {
          this.open = false
        }
      })
    },

    openTagsModal () {
      this.tippyInstance.hide()

      this.$modal.show('monitorTags', { monitor: this.monitor })
    },

    async run () {
      if (this.user.subscription_paused) {
        return false
      }

      this.tippyInstance.hide()

      let response = await monitorsApi.run(this.monitor.id)
      if (response && !response.success && response.message) {
        swal.fire({
          html: '<h4>' + response.message + '</h4>',
          title: '',
          showClass: {
            popup: ''
          },
          hideClass: {
            popup: ''
          },
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonColor: '#9ca3a1',
          cancelButtonText: 'Ok'
        })
      }

      this.$emit('run')
    },

    async pause () {
      this.tippyInstance.hide()

      await monitorsApi.pause(this.monitor.id)

      this.$emit('pause')
    },

    async duplicate () {
      this.tippyInstance.hide()

      await monitorsApi.duplicateMonitor(this.monitor.id)

      this.$emit('duplicated')
      this.action = 'duplicated'
      this.showSuccessMessage()
    },

    async resetMonitor () {
      const monitorName = this.monitor.nameOrUrl.replace('https://', '').replace('http://', '')
      swal.fire({
        html: '<h4>' + 'Are you sure that you want to reset "' + monitorName + '" monitor?</h4>',
        title: '',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        showCancelButton: true,
        confirmButtonColor: '#eb5757',
        cancelButtonColor: '#9ca3a1',
        confirmButtonText: 'Reset',
        cancelButtonText: 'Cancel'
      }).then(async (val) => {
        if (val.value === true) {
          this.tippyInstance.hide()
          try {
            await monitorsApi.resetStats(this.monitor.id)
            this.$emit('reset')
            this.action = 'reset'
            this.showSuccessMessage()
          } catch (e) {
            this.showErrorMessage()
          }
        }
      })
    },

    getRoute (name) {
      return {
        name: `monitors.single.${name}`,
        params: {
          id: this.monitor.id
        }
      }
    },

    async onDelete () {
      const monitorName = this.monitor.nameOrUrl.replace('https://', '').replace('http://', '')

      swal.fire({
        html: '<h4>' + 'Are you sure that you want to delete "' + monitorName + '" monitor?</h4>',
        title: '',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        showCancelButton: true,
        confirmButtonColor: '#eb5757',
        cancelButtonColor: '#9ca3a1',
        confirmButtonText: 'Delete',
        cancelButtonText: 'Cancel'
      }).then(async (val) => {
        if (val.value === true) {
          this.tippyInstance.hide()
          try {
            await monitorsApi.delete(this.monitor.id)
            this.$emit('delete', this.monitor.id)
            this.action = 'deleted'
            this.showSuccessMessage()
          } catch (e) {
            this.showErrorMessage()
          }
        }
      })
    },

    async onEnable () {
      const monitorName = this.monitor.nameOrUrl.replace('https://', '').replace('http://', '')

      swal.fire({
        html: '<h4>' + 'Are you sure that you want to enable "' + monitorName + '" monitor?</h4>',
        title: '',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        showCancelButton: true,
        confirmButtonColor: '#6551E0',
        cancelButtonColor: '#9ca3a1',
        confirmButtonText: 'Enable',
        cancelButtonText: 'Cancel'
      }).then(async (val) => {
        if (val.value === true) {
          this.tippyInstance.hide()
          try {
            await monitorsApi.enable(this.monitor.id)
            this.$emit('enable', this.monitor.id)
            this.action = 'enabled'
            this.showSuccessMessage()
          } catch (e) {
            this.showErrorMessage()
          }
        }
      })
    },

    showSuccessMessage () {
      swal.fire({
        title: 'Success',
        text: 'The monitor was successfully ' + this.action,
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        confirmButtonColor: '#6652e0',
        allowOutsideClick: false
      })
    },

    showErrorMessage () {
      swal.fire({
        title: 'Oops',
        text: 'Something went wrong ',
        showClass: {
          popup: ''
        },
        hideClass: {
          popup: ''
        },
        allowOutsideClick: true
      })
    }
  },

  computed: {
    activeSubscription () {
      return this.user.subscription_status === 'on_trial' || this.user.subscription_status === 'subscribed'
    },

    ...mapState('authentication', [
      'user'
    ])
  }
}
</script>

<style lang="scss" scoped>
  .MonitorActions {
    width: 32px;

    .dots-button {
      @include inline-flex-center;

      cursor: pointer;
      transition: 0.2s all;
      padding: 8px;
      border-radius: 4px;

      &:hover, &.open {
        background-color: #f6f5f7;

        ::v-deep svg g {
          fill: map-get($colors, purple-1) !important;
        }
      }
    }

    .nav {
      width: 200px;

      .nav-item {
        display: block;
        padding: 8px 10px;
        color: map-get($colors, gray-1);
        font-size: 15px;
        text-decoration: none;
        font-weight: 500;
        border-radius: 4px;

        &:hover {
          background-color: map-get($colors, purple-1);
          color: #fff;
        }
      }

      .spacer {
        margin: 10px 8px;
        border-bottom: 1px solid rgba(0,8,16,.15);
      }
    }

    .subscription-expired {
      padding: 4px 8px 8px 8px;
      font-size: 12px;
      color: #777;
    }
  }
</style>
